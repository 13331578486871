import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectHelpers } from '@bmng/helpers/object-helpers';
import { UrlHelpers } from '@bmng/helpers/url-helpers';
import { PromotionDiscount } from '@bmng/services/promotions/interfaces/promotion-discount.interface';
import { LangService } from '@seekda/angular-i18n';
import { AppliedFilter, DateRangeFromTo, MomentService } from '@seekda/bm-components';
import { forkJoin, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { EndpointService } from './../endpoint.service';
import { PromotionListItem } from './interfaces/promotion-list-item.interface';
// Interfaces
import {
    CodeValue,
    EnabledDisabled,
    EndpointPromotion,
    Promotion,
    PromotionAdjustment,
    PromotionFreeChildren,
    PromotionOverride,
    PromotionRestriction,
    PromotionRestrictionDateRange,
    PromotionsListConfig,
} from './interfaces/promotion.interface';

const PROMOTIONS_ENDPOINT = `${EndpointService.getBmBackendUrl()}/api/promotions`;
const moment = MomentService.get();

const dateBackendFormat: string = 'YYYY-MM-DD';

const losRestrictionTypes: string[] = [
    'minLos',
    'maxLos',
    'minLosThru',
    'maxLosThru',
];
const restrictionPropsWithRanges: string[] = [
    'stayDates',
    'bookingDates',
    'timeOfDay',
];

@Injectable()
export class PromotionsService extends EndpointService {

    constructor(
        http: HttpClient,
        private lang: LangService,
    ) {
        super(http);
    }

    /**
     * @obsolete Use getPromotionsList instead
     */
    getPromotions(hotelId: string, params: PromotionsListConfig = {}, filters: AppliedFilter = {}): Observable<Promotion[]> {
        const allParams = Object.assign({
            hotelId,
            languageCode: this.lang.getCurrentLanguage(),
        }, params, filters);
        const url = UrlHelpers.buildUrl(`${PROMOTIONS_ENDPOINT}/list.json`, allParams);

        return this.httpGet<EndpointPromotion[]>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map(promotions => promotions.map(promotion => this.preprocessPromotionReceived(promotion))),
        );
    }

    getPromotionsList(hotelId: string, params: PromotionsListConfig = {}, filters: AppliedFilter = {}): Observable<PromotionListItem[]> {
        const allParams = Object.assign({
            languageCode: this.lang.getCurrentLanguage(),
        }, params, filters);
        const url = UrlHelpers.buildUrl(`${PROMOTIONS_ENDPOINT}/${hotelId}`, allParams);

        return this.httpGet<PromotionListItem[]>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    getPromotionById(hotelId: string, promotionId: number, isSimplePromotion: boolean): Observable<Promotion> {
        const languageCode: string = this.lang.getCurrentLanguage();
        const closedDatesParam: string = isSimplePromotion ? '&mergeClosedDays=true' : '';
        const url: string = `${PROMOTIONS_ENDPOINT}/${hotelId}/${promotionId}?languageCode=${languageCode}${closedDatesParam}`;

        return this.httpGet<Promotion>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map(result => this.preprocessPromotionReceived(result, isSimplePromotion)));
    }

    getPromotionByIdWithoutPreprocessing(hotelId: string, promotionId: number): Observable<Promotion> {
        const languageCode: string = this.lang.getCurrentLanguage();
        const url: string = `${PROMOTIONS_ENDPOINT}/${hotelId}/${promotionId}?mergeClosedDays=true&languageCode=${languageCode}`;

        return this.httpGet<Promotion>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    save(promotion: Promotion): Observable<Promotion> {
        if (promotion.id) {
            return this.put(promotion, true);
        }
        return this.post(promotion, true);
    }

    saveWithoutPreprocessing(promotion: Promotion): Observable<Promotion> {
        if (promotion.id) {
            return this.httpPut(PROMOTIONS_ENDPOINT, promotion, EndpointService.HTTP_HEADERS_CONTENTTYPE);
        }
        return this.httpPost(PROMOTIONS_ENDPOINT, promotion, EndpointService.HTTP_HEADERS_CONTENTTYPE);
    }

    delete(hotelId: string, promotionId: number): Observable<boolean> {
        const url: string = `${PROMOTIONS_ENDPOINT}/${hotelId}/${promotionId}`;

        return this.httpDelete(url, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map(result => true));
    }

    bulkDelete(hotelId: string, promotionIds: number[]): Observable<number[]> {
        const url: string = `${PROMOTIONS_ENDPOINT}/${hotelId}/bulkDelete`;

        return this.httpPost(url, promotionIds, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map((result: number[]) => result));
    }

    private post(promotion: Promotion, isSimplePromotion: boolean): Observable<Promotion> {
        const data: Promotion = this.preprocessPromotionBeforeSending(promotion, isSimplePromotion);

        return this.httpPost(PROMOTIONS_ENDPOINT, data, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map((result: Promotion) => this.preprocessPromotionReceived(result, isSimplePromotion)));
    }

    private put(promotion: Promotion, isSimplePromotion: boolean): Observable<Promotion> {
        const data: Promotion = this.preprocessPromotionBeforeSending(promotion, isSimplePromotion);
        return this.httpPut(PROMOTIONS_ENDPOINT, data, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map((result: Promotion) => this.preprocessPromotionReceived(result, isSimplePromotion)));
    }

    getPromotionsFile(hotelId: string, extension: string): void {
        const languageCode: string = this.lang.getCurrentLanguage();
        const url: string = `${PROMOTIONS_ENDPOINT}/list.${extension}?hotelId=${hotelId}&languageCode=${languageCode}`;
        window.open(url, '_blank');
    }

    getPromotionsSuggestions(hotelId: string, languageCode: string, querystring: string): Observable<CodeValue[]> {
        const url: string = `${EndpointService.getBmBackendUrl()}/api/hotel/switch/api/autocomplete/${hotelId}/promotions/${querystring}`;

        return this.httpGet(url, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map(result => {
                const suggestions: CodeValue[] = [];

                Object.keys(result).forEach(key => {
                    if (!suggestions.includes(result[key].value)) {
                        suggestions.push(result[key].value);
                    }
                });

                return suggestions;
            }));
    }

    stateChange(hotelId: string, promotionIds: number[], state: EnabledDisabled): Observable<boolean> {
        const url = `${PROMOTIONS_ENDPOINT}/${hotelId}/stateUpdate`;

        return this.httpPost(url, {
            ids: promotionIds,
            state,
        }, EndpointService.HTTP_HEADERS);
    }

    // for the frontend we need empty stayDates, ... so we add them here and throw them away before sending
    private preprocessPromotionReceived(promotion: Promotion, isSimplePromotion: boolean = false): Promotion {
        promotion.isPromotionBookable = true;
        promotion.overrides = promotion.overrides || [];
        promotion.description = promotion.description || {};

        this.preprocessRestrictions(promotion, promotion.restrictions);
        this.preprocessAdjustments(promotion.adjustments);

        if (isSimplePromotion) {
            this.sanitizeClosedDates(promotion);
            this.preprocessDiscounts(promotion);
        }

        this.splitOverrides(promotion);
        promotion.overrides.forEach(override => this.preprocessRestrictions(promotion, override.restrictions));
        promotion.overrides.forEach(override => this.preprocessAdjustments(override.adjustments));

        this.preprocessRestrictionsDates(promotion);

        if (!this.hasAbsoluteDiscounts(promotion)) {
            promotion.currencyCode = null;
        }

        if (!promotion.overrideType) {
            promotion.overrideType = 'LOCAL_RATES';
        }

        return promotion;
    }

    private splitOverrides(promotion: Promotion): void {
        const overrides: PromotionOverride[] = [];

        promotion.overrides.forEach(override => {
            const rates = ObjectHelpers.copy(override.rates);
            rates.forEach(rateCode => {
                override.rates = [ rateCode ];
                overrides.push(ObjectHelpers.copy(override));
            });
        });

        promotion.overrides = overrides;
    }

    private sanitizeDiscounts(discounts: PromotionDiscount[]): PromotionDiscount[] {
        if (!!discounts) {
            discounts.forEach(discount => {
                if (!discount.inflation?.value) {
                    discount.inflation = null;
                }
            });

            return discounts;
        }

        return null;
    }

    private sanitizeClosedDates(copy: Promotion): void {
        if (!copy.restrictions) {
            return;
        }

        copy.restrictions.closedDays = null;
    }

    private preprocessDiscounts(copy: Promotion): void {
        if (!copy.discounts || copy.discounts.length === 0) {
            copy.discounts = [];
            const discount: PromotionDiscount = {
                type: 'STANDARD',
                restrictions: {
                    minAdvanceBookingOffset: null,
                    maxAdvanceBookingOffset: null,
                    minLos: null,
                    maxLos: null,
                },
                inflation: copy.adjustments.inflation,
                discount: copy.adjustments.discount,
            };
            const isDiscountSet = (!!discount.discount && !!discount.discount.value);

            if (copy.type === 'CROSS_OUT') {
                discount.inflation = copy.adjustments.inflation;
            } else if (copy.type === 'EARLY_BOOKING') {
                discount.type = 'EARLY_BIRD';
                discount.restrictions.minAdvanceBookingOffset = copy.adjustments.bookingOffset.minAdvanceBookingOffset;
            } else if (copy.type === 'FREE_CHILDREN') {
                /* nothing to do here */
            } else if (copy.type === 'FREE_NIGHTS') {
                /* nothing to do here */
            } else if (copy.type === 'LOS') {
                discount.type = 'LOS';
                discount.restrictions.minLos = copy.restrictions.los.minLos;
            } else if (copy.type === 'LAST_MINUTE') {
                discount.type = 'LAST_MINUTE';
                discount.restrictions.maxAdvanceBookingOffset = copy.adjustments.bookingOffset.maxAdvanceBookingOffset;
            }

            if (isDiscountSet) {
                copy.discounts.push(discount);
            }
        }

        this.migrateBookingOffsets(copy);
    }

    private migrateBookingOffsets(copy: Promotion): void {
        const bookingOffset = copy.adjustments.bookingOffset;

        if (bookingOffset) {
            if (bookingOffset.maxAdvanceBookingOffset > 0) {
                this.migrateBookingOffsetForType('LAST_MINUTE', copy);
            }

            if (bookingOffset.minAdvanceBookingOffset > 0) {
                this.migrateBookingOffsetForType('EARLY_BIRD', copy);
            }
        }
    }

    private migrateBookingOffsetForType(type: 'EARLY_BIRD' | 'LAST_MINUTE', copy: Promotion): void {
        const discountIndex = copy.discounts.findIndex(d => d.type === type);

        if (discountIndex === -1) {
            const discount: PromotionDiscount = {
                type,
                restrictions: {
                    minAdvanceBookingOffset: type === 'EARLY_BIRD' ? copy.adjustments.bookingOffset.minAdvanceBookingOffset : null,
                    maxAdvanceBookingOffset: type === 'LAST_MINUTE' ? copy.adjustments.bookingOffset.maxAdvanceBookingOffset : null,
                    minLos: null,
                    maxLos: null,
                },
                inflation: null,
                discount: { type: 'AMOUNT', value: 0 },
            };

            copy.discounts.push(discount);

            if (type === 'EARLY_BIRD') {
                copy.adjustments.bookingOffset.minAdvanceBookingOffset = 0;
            } else {
                copy.adjustments.bookingOffset.maxAdvanceBookingOffset = 0;
            }
        }
    }

    private preprocessRestrictionsDates(copy: Promotion): void {
        const restrictionDates: { [key: string]: string } = {
            bookingDates: 'bookable',
            stayDates: 'available',
        };
        let overrides: PromotionOverride[] = copy.overrides;
        // Includes promotion in overrides
        overrides = overrides.concat([ copy ]);
        overrides.forEach(override => {
            Object.keys(restrictionDates).forEach((key: string) => {
                const propName: string = restrictionDates[key];
                const restrictionDate: PromotionRestrictionDateRange | null = override.restrictions[key]
                    || (<PromotionRestrictionDateRange>override.restrictions[key]);
                if (restrictionDate && restrictionDate.type === 'ONLY' && restrictionDate.ranges.length > 0) {
                    // The date format is 'YYYY-MM-DD', I do the comparison as a string
                    if (!copy[`${propName}From`] || (copy[`${propName}From`] && copy[`${propName}From`] > restrictionDate.ranges[0].from)) {
                        copy[`${propName}From`] = restrictionDate.ranges[0].from;
                    }
                    if (!copy[`${propName}Until`] || (copy[`${propName}Until`] &&
                        copy[`${propName}Until`] < restrictionDate.ranges[restrictionDate.ranges.length - 1].to)) {
                        copy[`${propName}Until`] = restrictionDate.ranges[restrictionDate.ranges.length - 1].to;
                    }
                }
            });
        });
        // If the property doesn't exist, initialize it with the default label
        Object.keys(restrictionDates).forEach((key: string) => {
            const propName: string = restrictionDates[key];
            if (!copy[`${propName}From`]) {
                copy[`${propName}From`] = '';
            }
            if (!copy[`${propName}Until`]) {
                copy[`${propName}Until`] = '';
            }
        });
    }

    private preprocessAdjustments(adjustment: PromotionAdjustment): void {
        adjustment.taxPolicy = adjustment.taxPolicy || '';

        if (!adjustment.freeNights) {
            adjustment.freeNights = {
                paid: 0,
                free: 0,
                cumulative: false,
            };
        }

        if (!adjustment.freeChildren) {
            adjustment.freeChildren = [];
        } else {
            // Sort ascending
            adjustment.freeChildren.sort((range1, range2) => Math.sign(range1.upToAge - range2.upToAge));

            let lastFromAge: number = 0;

            adjustment.freeChildren = adjustment.freeChildren.filter((freeKid: PromotionFreeChildren) => {
                freeKid.fromAge = +lastFromAge;
                lastFromAge = freeKid.upToAge + 1;
                return (freeKid.count > 0);
            });
        }

        if (!adjustment.discount) {
            adjustment.discount = {
                type: 'PERCENT',
                value: null,
            };
        }

        if (!adjustment.inflation) {
            adjustment.inflation = {
                type: 'PERCENT',
                value: null,
            };
        }

        if (adjustment.mealPlanCode === null) {
            adjustment.mealPlanCode = 0;
        }
    }

    private preprocessRestrictions(promo: Promotion, restriction: PromotionRestriction): void {
        if (restriction.los) {
            losRestrictionTypes.forEach((key: string) => {
                if (restriction.los && restriction.los[key] === 0) {
                    restriction.los[key] = null;
                }
            });
        }

        if (!restriction.deviceTypeFencing) {
            restriction.deviceTypeFencing = '';
        }

        if (!restriction.geoFencing) {
            restriction.geoFencing = {
                type: '',
                countries: [],
            };
        }

        if (!restriction.channels) {
            restriction.channels = {
                type: '',
                channels: [],
            };
        }

        if (!restriction.closedDays) {
            restriction.closedDays = [];
        }

        restrictionPropsWithRanges.forEach((prop: string) => {
            if (!restriction[prop]) {
                restriction[prop] = {
                    type: '',
                    ranges: [],
                };
            } else {
                // Sort ascending
                restriction[prop].ranges = restriction[prop].ranges || [];
                restriction[prop].ranges.sort((range1: DateRangeFromTo, range2: DateRangeFromTo) => {
                    if (range1.from > range2.from) {
                        return 1;
                    } else if (range1.from < range2.from) {
                        return -1;
                    } else {
                        return 0;
                    }
                });

                const lastRangeIndex: number = restriction[prop].ranges.length - 1;

                restriction[prop].ranges.forEach((range: DateRangeFromTo, index: number) => {
                    // Check if the promotion is bookable based on the stay dates
                    if ((prop === 'stayDates') && (lastRangeIndex === index)) {
                        if (restriction[prop].type === 'ONLY') {
                            const today = moment();
                            promo.isPromotionBookable = !today.isAfter(moment(range.to, dateBackendFormat));
                        } else {
                            promo.isPromotionBookable = true;
                        }
                    }
                });
            }
        });
    }

    private preprocessPromotionBeforeSending(promotion: Promotion, isSimplePromotion: boolean): Promotion {
        const copy: Promotion = JSON.parse(JSON.stringify(promotion));

        preprocessRestrictions(copy.restrictions);
        preprocessAdjustments(copy.adjustments);

        copy.overrides.forEach((override: PromotionOverride) => {
            preprocessRestrictions.call(this, override.restrictions);
            preprocessAdjustments.call(this, override.adjustments);
            delete override.type;
        });

        if (copy.overrides && !copy.overrides.length) {
            delete copy.overrides;
        }

        if (isSimplePromotion) {
            if (!!copy.discounts && copy.discounts.length > 0) {
                copy.discounts = this.sanitizeDiscounts(copy.discounts);
            }

            if (!!copy.overrides) {
                copy.overrides.forEach(override => {
                    override.discounts = this.sanitizeDiscounts(override.discounts);
                });
            }
        } else {
            copy.discounts = null;
            delete copy.discounts;
        }

        const unnecessaryProps: string[] = [ 'role', 'relatedTo', 'isPromotionBookable', 'bookableUntil', 'bookableFrom',
            'availableFrom', 'availableUntil', 'deviceType' ];

        unnecessaryProps.forEach((key: string) => {
            delete copy[key];
        });

        return copy;

        function preprocessRestrictions(restriction: PromotionRestriction): void {
            if (restriction.geoFencing && restriction.geoFencing.type === '') {
                delete restriction.geoFencing;
            }

            if (restriction.channels && restriction.channels.type === '') {
                delete restriction.channels;
            }

            if (restriction.deviceTypeFencing === '') {
                delete restriction.deviceTypeFencing;
            }

            if (!!restriction.closedDays && restriction.closedDays.length === 0) {
                delete restriction.closedDays;
            }

            restrictionPropsWithRanges.forEach((prop: string) => {
                if (!!restriction[prop] && restriction[prop].type === '' && restriction[prop].ranges.length === 0) {
                    delete restriction[prop];
                }
            });
        }

        function preprocessAdjustments(adjustment: PromotionAdjustment): void {
            if (adjustment.inflation && !adjustment.inflation.value) {
                delete adjustment.inflation;
            }

            if (adjustment.discount && !adjustment.discount.value) {
                delete adjustment.discount;
            }

            if (adjustment.taxPolicy === '') {
                adjustment.taxPolicy = null;
            }

            if (!adjustment.freeChildren?.length || adjustment.freeChildren[0].upToAge === 0) {
                delete adjustment.freeChildren;
            } else {
                // Sort ascending
                adjustment.freeChildren.sort((range1, range2) => Math.sign(range1.upToAge - range2.upToAge));

                // Complete the non-consecutive ranges
                const newFreeChildren: PromotionFreeChildren[] = [];
                let nextFrom: number = 0; // The minimum value
                adjustment.freeChildren.forEach((freeKid: PromotionFreeChildren) => {
                    if (freeKid.fromAge !== nextFrom) {
                        // non-consecutive
                        newFreeChildren.push({
                            count: 0,
                            upToAge: freeKid.fromAge - 1,
                        });
                    }

                    nextFrom = freeKid.upToAge + 1;
                });

                if (newFreeChildren.length) {
                    // Complete the range
                    adjustment.freeChildren = newFreeChildren.concat(adjustment.freeChildren);
                }

                adjustment.freeChildren.forEach((freeKid: PromotionFreeChildren) => {
                    delete freeKid.fromAge;
                });
            }

            if (!adjustment.freeNights || adjustment.freeNights.free === 0) {
                delete adjustment.freeNights;
            }

            if (adjustment.mealPlanCode === 0) {
                delete adjustment.mealPlanCode;
            }
        }
    }

    bulkUpdate(promotions: Promotion[], isSimplePromotion: boolean): Observable<number> {
        const requests: Observable<Promotion>[] = [];

        promotions.forEach((promotion: Promotion) => {
            requests.push(
                this.put(promotion, isSimplePromotion).pipe(first()),
            );
        });

        return forkJoin(requests).pipe(
            map((results: Promotion[]) => results.length));
    }

    getCodesInUse(hotelId: string): Observable<string[]> {
        const currentTime: number = new Date().getMilliseconds();
        const languageCode: string = this.lang.getCurrentLanguage();
        const url: string = `${PROMOTIONS_ENDPOINT}/list.json?hotelId=${hotelId}&languageCode=${languageCode}&_t=${currentTime}`;

        return this.httpGet<Promotion[]>(url, EndpointService.HTTP_HEADERS_CONTENTTYPE).pipe(
            map((result: Promotion[]) => result.map((promo: Promotion) => promo.code)));
    }

    public hasAbsoluteDiscounts(promotion: Promotion): boolean {
        function hasAbsoluteDiscountInAdjustments(adjustments: PromotionAdjustment): boolean {
            if (!!adjustments.discount && adjustments.discount.type === 'AMOUNT') {
                return true;
            }

            if (!!adjustments.inflation && adjustments.inflation.type === 'AMOUNT') {
                return true;
            }

            return false;
        }

        function hasLegacyAbsoluteDiscounts(): boolean {
            return hasAbsoluteDiscountInAdjustments(promotion.adjustments);
        }

        function hasAbsoluteDiscountInLegacyOverride(): boolean {
            let hasOverrideAbsoluteDiscount = false;

            if (!!promotion.overrides) {
                promotion.overrides.forEach(override => {
                    hasOverrideAbsoluteDiscount = hasOverrideAbsoluteDiscount || hasAbsoluteDiscountInAdjustments(override.adjustments);
                });
            }

            return hasOverrideAbsoluteDiscount;
        }

        function hasPromotionDiscountAbsoluteType(discount: PromotionDiscount): boolean {
            if (discount.discount.type === 'AMOUNT') {
                return true;
            }

            if (!!discount.inflation && discount.inflation.type === 'AMOUNT') {
                return true;
            }

            return false;
        }

        function hasAbsoluteDiscounts(): boolean {
            if (!!promotion.discounts) {
                return promotion.discounts.filter(hasPromotionDiscountAbsoluteType).length > 0;
            }

            return false;
        }

        function hasAbsoluteDiscountsInOverrides(): boolean {
            if (!!promotion.overrides) {
                let hasAbsoluteOverrideDiscount = false;

                promotion.overrides.forEach(override => {
                    if (override.discounts) {
                        override.discounts.forEach(discount => {
                            hasAbsoluteOverrideDiscount = hasAbsoluteOverrideDiscount || hasPromotionDiscountAbsoluteType(discount);
                        });
                    }
                });

                return hasAbsoluteOverrideDiscount;
            }

            return false;
        }

        if (!promotion) {
            throw new Error('Invalid argument - must not be null object');
        }

        return hasAbsoluteDiscounts() || hasAbsoluteDiscountsInOverrides() ||
            hasLegacyAbsoluteDiscounts() || hasAbsoluteDiscountInLegacyOverride();
    }
}
